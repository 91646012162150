<template>
	<div>
		<div class="d-flex justify-space-between align-center mb-2 mt-2">
			<div class="input-wrap">
				<h6 class="mb-2">Comprobantes</h6>
			</div>
			<div class="action-btn-wrap">
				<v-btn color="accent" :to="{ name: 'documentCreate' }">Nuevo</v-btn>
			</div>
		</div>

		<v-card color="secondary" class="pa-3">
			<data-table-docs :resource="resource">
				<tr slot="heading">
					<th class="text-right">N°</th>
					<th class="text-center">FECHA DE EMISIÓN</th>
					<th>COMPROBANTE</th>
					<th class="text-right">NÚMERO</th>
					<th class="text-right">RUC, DNI</th>
					<th>DENOMINACIÓN</th>
					<th>NOTAS C/D</th>
					<th class="text-right">ALQ.</th>
					<th class="text-center">ESTADO</th>
					<th class="text-center">M.</th>
					<th class="text-right">T. GRABADO</th>
					<th class="text-right">T. IGV</th>
					<th class="text-right">TOTAL</th>
					<th class="text-center">DESCARGAS</th>
					<th class="text-center">ACCIONES</th>
				</tr>
				<tr
					slot-scope="{ index, row }"
					:class="{
						'error--text': row.state_type_id === '11',
						'warning--text': row.state_type_id === '13',
						'info--text': row.state_type_id == '09',
					}"
				>
					<td class="text-right">{{ index }}</td>
					<td class="text-center">{{ row.date_of_issue_show }}</td>
					<td>{{ row.document_type_description }}</td>
					<td class="text-right">
						{{ row.number }}
						<small v-if="row.affected_document" v-text="row.affected_document"></small>
					</td>
					<td class="text-right ">{{ row.customer_number }}</td>
					<td>{{ row.customer_name }}</td>
					<td>
						<template v-for="(row, index) in row.notes">
							<label class="d-block" :key="index"
								>{{ row.note_type_description }}: {{ row.description }}</label
							>
						</template>
					</td>
					<td class="text-right">
						{{ row.rental }}
					</td>
					<td class="text-center">
						<v-chip
							v-if="row.state_type_id === '11'"
							class="ma-2"
							color="red"
							text-color="white"
							x-small
						>
							{{ row.state_type_description }}
						</v-chip>
						<v-chip
							v-if="row.state_type_id === '13'"
							class="ma-2"
							color="orange"
							text-color="white"
							x-small
						>
							{{ row.state_type_description }}
						</v-chip>
						<v-chip
							v-if="row.state_type_id === '01'"
							class="ma-2"
							color="default"
							text-color="white"
							dark
							x-small
						>
							{{ row.state_type_description }}
						</v-chip>
						<v-chip
							v-if="row.state_type_id === '03'"
							class="ma-2"
							color="info"
							text-color="white"
							x-small
						>
							{{ row.state_type_description }}
						</v-chip>
						<v-chip
							v-if="row.state_type_id === '05'"
							class="ma-2"
							color="green"
							text-color="white"
							x-small
						>
							{{ row.state_type_description }}
						</v-chip>
						<v-chip
							v-if="row.state_type_id === '07'"
							class="ma-2"
							color="secondary"
							text-color="white"
							x-small
						>
							{{ row.state_type_description }}
						</v-chip>
						<v-chip
							v-if="row.state_type_id === '09'"
							class="ma-2"
							color="red"
							text-color="white"
							x-small
						>
							{{ row.state_type_description }}
						</v-chip>
					</td>
					<td class="text-center">
						{{ row.currency_type_id == 'PEN' ? 'S/' : '$' }}
					</td>
					<td class="text-right">{{ row.total_taxed }}</td>
					<td class="text-right">{{ row.total_igv }}</td>
					<td class="text-right">{{ row.total }}</td>
					<td class="text-center">
						<v-btn
							color="error"
							class="mr-1"
							x-small
							dark
							@click.prevent="clickDownload(row.download_pdf)"
							v-if="row.has_pdf"
						>
							PDF
						</v-btn>
						<v-btn
							color="success"
							x-small
							dark
							@click.prevent="clickDownload(row.download_xml)"
							v-if="row.has_xml"
						>
							XML
						</v-btn>

						<v-btn
							color="info"
							class="ma-1"
							x-small
							dark
							@click.prevent="clickDownload(row.download_cdr)"
							v-if="row.has_cdr"
						>
							CDR
						</v-btn>
					</td>
					<td class="text-center">
						<v-menu bottom origin="center center" transition="scale-transition">
							<!-- <v-card dark color="secondary"> -->
								<template v-slot:activator="{ on, attrs }">
									<v-btn color="accent" dark small v-bind="attrs" v-on="on">
										Opciones
									</v-btn>
								</template>
								<v-list dense>
									<v-list-item @click="clickPrint(row.id)">
										<v-list-item-title>Imprimir</v-list-item-title>
									</v-list-item>
									<v-list-item
										@click.prevent="clickResend(row.id)"
										v-if="row.btn_resend"
									>
										<v-list-item-title>Reenviar</v-list-item-title>
									</v-list-item>
									<v-list-item
										v-if="row.btn_consult_cdr"
										@click.prevent="clickConsultCdr(row.id)"
									>
										<v-list-item-title>Consultar CDR</v-list-item-title>
									</v-list-item>
									<v-list-item @click.prevent="clickOptions(row.id)">
										<v-list-item-title>Opcciones</v-list-item-title>
									</v-list-item>
									<v-list-item v-if="row.btn_note">
										<v-list-item-title>Generar Nota</v-list-item-title>
									</v-list-item>
									<v-list-item
										@click.prevent="clickVoided(row.id)"
										v-if="row.btn_voided"
									>
										<v-list-item-title>Anular</v-list-item-title>
									</v-list-item>
								</v-list>
							<!-- </v-card> -->
						</v-menu>
					</td>
				</tr>
			</data-table-docs>
		</v-card>
		
		<documents-voided :showDialog.sync="showDialogVoided" :recordId="recordId">
		</documents-voided>

		<document-options
			:showDialog.sync="showDialogOptions"
			:recordId="recordId"
			:showClose="true"
		>
		</document-options>
	</div>
</template>

<script>
import DataTableDocs from 'Components/Global/DataTableDocuments';
import DocumentOptions from './Component/Options';
import DocumentsVoided from './Component/voided';
import printJS from 'print-js';

export default {
	components: {
		DocumentsVoided,
		DocumentOptions,
		DataTableDocs,
	},
	data() {
		return {
			showDialogVoided: false,
			showDialogOptions: false,
			resource: 'documents',
			recordId: null,
		};
	},
	
	methods: {
		clickVoided(recordId = null) {
			this.recordId = recordId;
			this.showDialogVoided = true;
		},
		async clickPrint(recordId) {
			await this.$http.get(`/${this.resource}/record/${recordId}`).then((response) => {
				this.form = response.data.data;

				let config = this.form.invoice.config;

				if (config.print_mode == 'direct') {
					let data = JSON.stringify(this.form.invoice);

					let url = `https://${config.pc_ip}/print-api/print/bill`;

					fetch(url, { method: 'POST', body: data })
						.then((dataWrappedByPromise) => dataWrappedByPromise.json())
						.then((data) => {
							if (data.success) {
								this.$message.success(data.message);
							} else {
								this.$message.error(data.message);
							}
						})
						.catch((error) => {
							console.error('Error: ', error);
						});
				} else {
					printJS(`${this.form.print}`);
				}
			});
		},
		clickDownload(download) {
			window.open(download, '_blank');
		},
		clickResend(document_id) {
			this.$http
				.post(`/${this.resource}/send/${document_id}`)
				.then((response) => {
					if (response.data.success) {
						this.$message.success(response.data.message);
						this.$eventHub.$emit('reloadData', this.resource);
					} else {
						this.$message.error(response.data.message);
					}
				})
				.catch((error) => {
					this.$message.error(error.response.data.message);
				});
		},
		clickConsultCdr(documentID) {
            this.$http
                .get(`/${this.resource}/consult_cdr/${documentID}`)
                .then(response => {
                    if (response.data.success) {
                        this.$message.success(response.data.message);
                        this.$eventHub.$emit("reloadData", this.resource);
                    } else {
                        this.$message.error(response.data.message);
                    }
                })
                .catch(error => {
                    this.$message.error(error.response.data.message);
                });
        },
		clickOptions(recordId = null) {
			this.recordId = recordId;
			this.showDialogOptions = true;
		},
		tooltip(row, message = true) {
			if (message) {
				if (row.shipping_status) return row.shipping_status.message;

				if (row.sunat_shipping_status) return row.sunat_shipping_status.message;

				if (row.query_status) return row.query_status.message;
			}

			if (row.shipping_status || row.sunat_shipping_status || row.query_status) return true;

			return false;
		},
	},
};
</script>
